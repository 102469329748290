<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-30 13:07:18
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:01:58
 * @Description: 海关申报单管理
 * @FilePath: \src\views\App\CustomsDeclaration\CustomsDeclarationManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 检索 ------------- -->
    <div class="table-search-box">
      <div class="table-search-title">检索</div>
      <div class="table-search-content">
        <div class="table-search-item">
          <label>组织筛选：</label>
          <organization-picker v-model="p.kor_id"></organization-picker>
        </div>

        <div class="table-search-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="
              p.pageNo = 1;
              _Load();
            "
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_OpenCreatePackageModel()"
        >创建新包裹</el-button
      >
      <el-button
        size="small"
        type="info"
        icon="el-icon-refresh"
        @click="_Load()"
        >刷新</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      row-key="id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        fixed="left"
        width="50px"
      ></el-table-column>

      <!-- 发货渠道 -->
      <el-table-column
        label="发货渠道"
        prop="kch_name"
        align="center"
        fixed="left"
        width="130px"
      ></el-table-column>

      <!-- 客户昵称 -->
      <el-table-column
        label="客户昵称"
        prop="ku_name"
        show-overflow-tooltip
        align="center"
        width="160px"
      ></el-table-column>

      <!-- 申报明细 -->
      <el-table-column
        label="申报明细"
        show-overflow-tooltip
        align="left"
        :formatter="_ShowItems"
      ></el-table-column>

      <!-- 清关类型 -->
      <el-table-column label="清关类型" align="center" width="130px">
        <template slot-scope="s">
          <el-tag size="small" effect="plain" v-if="s.row.cc_type == 230110"
            >个人目录</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.cc_type == 230115"
            >个人简易</el-tag
          >
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.cc_type == 230120"
            >企业简易</el-tag
          >
          <el-tag size="small" effect="plain" type="info" v-else>未知</el-tag>
        </template>
      </el-table-column>

      <!-- 申报价格 -->
      <el-table-column
        label="申报价格"
        prop="count"
        align="center"
        width="100px"
      ></el-table-column>

      <!-- 收件人姓名 -->
      <el-table-column
        label="收件人姓名"
        prop="rec_name"
        align="center"
        width="100px"
      ></el-table-column>

      <!-- 收件人联系方式 -->
      <el-table-column
        label="收件人联系方式"
        prop="phone"
        align="center"
        width="100px"
      ></el-table-column>

      <!-- 邮编 -->
      <el-table-column
        label="邮编"
        prop="zip_code"
        align="center"
        width="100px"
      ></el-table-column>

      <!-- 收件地址 -->
      <el-table-column
        label="收件地址"
        prop="address"
        align="center"
        width="100px"
      ></el-table-column>

      <!-- 个人报关号码 -->
      <el-table-column
        label="个人报关号码"
        prop="rec_id"
        align="center"
        width="100px"
      ></el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" fixed="right" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdatePackageModel(s.row.kpa_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelPackage(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[10, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-customs-declaration ref="nu-package"></nu-customs-declaration>
  </div>
</template>

<script>
import NuCustomsDeclaration from "./NUCustomsDeclaration";
import { getCustomsDeclarationTable, delPackage } from "./api"; //页面专有接口
import OrganizationPicker from "@/components/organizationPicker";
export default {
  name: "PackageManage",
  components: {
    NuCustomsDeclaration,
    OrganizationPicker,
  },
  data() {
    return {
      p: {
        kor_id: "", //组织ID
        pageNo: 1, //当前页
        pageSize: 10, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合
    };
  },
  watch: {
    //监听组织ID变动加载数据
    "p.kor_id": {
      handler() {
        this._Load();
      },
    },
  },
  methods: {
    //加载表格数据
    _Load() {
      getCustomsDeclarationTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
        kor_id: this.p.kor_id,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.p.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 150004) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开包裹添加组件
    _OpenCreatePackageModel() {
      this.$refs["nu-package"].open(0);
    },

    //打开包裹修改组件
    _OpenUpdatePackageModel(id) {
      this.$refs["nu-package"].open(id);
    },

    //创建货品明细
    _ShowItems(row) {
      let rows = JSON.parse(row.items_list);
      var ai = 0;
      let items = "";
      for (var i = 0; i < rows.length; i++) {
        let item = rows[i];
        if (i < 5) {
          if (i > 0) {
            items += ", ";
          }
          items += item.name + " *" + item.amount;
        } else {
          ai = ai + item.amount; //计数
          if (i === rows.length - 1) {
            items += "及其他" + ai + "件物品";
          }
        }
      }
      return items;
    },

    //删除
    _DelPackage(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除包裹【" +
          data.rfid +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delPackage({ kpa_id: data.kpa_id })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
};
</script>
